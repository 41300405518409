import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import get from 'lodash/get';
import ContentfulBlockArticles from './ContentfulBlockArticles';

const ContentfulWackyNews = () => {
     return (
          <StaticQuery
               query={graphql`
                    query WackyArticles {
                         allContentfulWackyBlog(sort: { fields: createdAt, order: DESC }, filter: {hideOnBlog: {ne: true}}) {
                              nodes {
                                   slug
                                   articleTitle
                                   wackyContentType {
                                        contentTypeName
                                   }
                                   featuredImage {
                                        file {
                                             url
                                        }
                                   }
                                   socialImage {
                                        file {
                                             url
                                        }
                                   }
                                   hideOnBlog
                                   yearWin
                                   petCategory
                              }
                         }
                    }
               `}
               render={(data) => {
                    const articles = get(data, 'allContentfulWackyBlog.nodes');

                    return (
                         <section className="hambone-blog-category">
                              <div className="container">
                                   <ContentfulBlockArticles articles={articles} articlePerPage={6} />
                              </div>
                         </section>
                    );
               }}
          />
     );
};

export default ContentfulWackyNews;
