import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { getArticleOrCategorySlugs } from '../../utilities';
import get from 'lodash/get';
import ArticleCardBlue from '../Common/Article/ArticleCardBlue';

const NewArticles = () => {
     return (
          <StaticQuery
               query={graphql`
                    query NewArticlesQuery {
                         allContentfulHzArticle(limit: 7, sort: { fields: createdAt, order: DESC }) {
                              edges {
                                   node {
                                        slug
                                        omniturePageNameLegacy
                                        contentful_id
                                        contentName
                                        shortDescription
                                        featuredImage {
                                             file {
                                                  url
                                                  details {
                                                       image {
                                                            width
                                                            height
                                                       }
                                                  }
                                             }
                                        }
                                        photos {
                                             file {
                                                  url
                                             }
                                        }
                                        category {
                                             slug
                                             breadcrumbValue
                                             relatedWhitePaper {
                                                  file {
                                                       url
                                                       details {
                                                            image {
                                                                 width
                                                                 height
                                                            }
                                                       }
                                                  }
                                             }
                                             parentCategory {
                                                  slug
                                                  breadcrumbValue
                                                  parentCategory {
                                                       slug
                                                       breadcrumbValue
                                                       parentCategory {
                                                            slug
                                                            breadcrumbValue
                                                            parentCategory {
                                                                 slug
                                                                 breadcrumbValue
                                                            }
                                                       }
                                                  }
                                             }
                                        }
                                        socialImage {
                                             file {
                                                  url
                                             }
                                        }
                                   }
                              }
                         }
                    }
               `}
               render={(data) => {
                    const articles = get(data, 'allContentfulHzArticle.edges');
                    if (articles.length === 0) {
                         return <></>;
                    }
                    const firstRecentArticleSlug =
                         '/healthzone/' +
                         getArticleOrCategorySlugs(articles[0].node.category)
                              .reverse()
                              .join('/') +
                         '/' +
                         articles[0].node.slug +
                         '/';

                    const articleBanner = articles[0].node;
                    const backgroundImageUrl = articleBanner.featuredImage.file.url;

                    return (
                         <>
                              <section
                                   className="healthzone-banner"
                                   style={{ backgroundImage: `url(${backgroundImageUrl})`, '--background-image-mobile': `url(${backgroundImageUrl})` }}
                              >
                                   <div className="container">
                                        <div className="row">
                                             <div className="col-lg-7 col-xl-6">
                                                  <div className="healthzone-banner-box">
                                                       {articleBanner.contentName && <h1>{articleBanner.contentName}</h1>}
                                                       {articleBanner.shortDescription && <p>{articleBanner.shortDescription}</p>}
                                                       <a href={firstRecentArticleSlug} className="healthzone-banner-readmore">
                                                            Read more
                                                       </a>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </section>
                              <section className="home-articles">
                                   <div className="container">
                                        <div className="row articles-wrap">
                                             {articles.map(({ node: article }, i) => {
                                                  let slugs =
                                                       '/healthzone/' +
                                                       getArticleOrCategorySlugs(article.category)
                                                            .reverse()
                                                            .join('/') +
                                                       '/' +
                                                       article.slug +
                                                       '/';
                                                  if (i != 0) {
                                                       return (
                                                            <div className="col-md-6 col-lg-4 article-block" key={i}>
                                                                 <ArticleCardBlue article={article} slugs={slugs} />
                                                            </div>
                                                       );
                                                  }
                                             })}
                                        </div>
                                   </div>
                              </section>
                         </>
                    );
               }}
          />
     );
};

export default NewArticles;
