import React from 'react';
import { StaticQuery, graphql } from "gatsby";
import get from 'lodash/get';
import PgArticle from './PgArticle';

const PgHome = (props) => {
     return <StaticQuery
          query={graphql`
               query LastestPgArticleQuery {
                    allContentfulPgCategory(sort: {fields: slug, order: DESC}, filter: {isYearCategory: {eq: true}}, limit: 1) {
                         edges {
                              node {
                                   categoryName
                                   slug
                                   pg__category {
                                        categoryName
                                        slug
                                        pg__article {
                                             contentName
                                             contentful_id
                                             slug
                                             breadcrumbValue
                                             articleName
                                             shortDescription
                                             body {
                                                  raw
                                                  references { 
                                                       ...ContentfulRichTextReferenceFields
                                                  }
                                             }
                                             featuredImage {
                                                  file {
                                                       url
                                                  }
                                             }
                                             sidebarTitle
                                             category {
                                                  slug
                                             }
                                        }
                                   }
                              }
                         }
                    }
               }
          `}
          render={
               data => {
                    const lastestYear = get(data, 'allContentfulPgCategory.edges[0]');

                    let monthAndYears = lastestYear.node.pg__category.sort((a, b) => {
                         return new Date(b.categoryName) - new Date(a.categoryName);
                    });;

                    monthAndYears = monthAndYears.filter(month => {
                         return month.pg__article != null
                    })

                    const lastestArticle = monthAndYears[0].pg__article[0];

                    return (
                         <>
                              <PgArticle homeSidebar={true} article={lastestArticle} sidebarImage={props.rows ? props.rows[0] : ''} />
                         </>
                    )
               }
          }
     />;
}

export default PgHome;