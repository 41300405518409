import React from 'react';
import { Image } from '../Image';

const ArticleCardBlue = ({ article, slugs }) => {
     return (
          <div className="article-card-blue">
               <div className="article-card-blue-image">
                    <a href={slugs} tabIndex={-1}>
                         {article.featuredImage ? (
                              <Image image={article.featuredImage} altOverride={article.contentName ? article.contentName : ''} />
                         ) : (
                              <img loading="lazy" src="/images/no-dog.png" alt="no-dog" />
                         )}
                    </a>
               </div>
               <div className="article-card-blue-content">
                    {article.contentName && (
                         <a href={slugs}>
                              <h3>{article.contentName}</h3>
                         </a>
                    )}
               </div>
          </div>
     );
};

export default ArticleCardBlue;
